<template>
  <div class="xcontainer">
    <h1 v-if="error.statusCode === 404">Not found</h1>
    <h1 v-else>An error occurred</h1>
    <p>{{ error.message }}</p>
    <c-link to="/" title="Explore plugins"> Explore plugins </c-link>
  </div>
</template>

<script>
  export default {
    name: 'ErrorLayout',
    layout: 'site',
    props: {
      error: {
        type: Object,
        required: true,
      },
    },
  };
</script>
